import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { loginApi } from '../../utils/axios';
import { useParams, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDataApi } from '../../redux/Reducers/ProfileReducer';
import { getAllServiceAddressesApi } from '../../redux/Reducers/ServiceAddressesReducer';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
import { getGoogleAuthUrl, signinThroughGoogle } from '../../redux/Reducers/commonApis';
function SigninPage({ setType }) {
  const tenant = import.meta.env.VITE_TENANT_NAME;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('processing...');
  // const { profileData } = useSelector((state) => state.profile);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data, e) => {
    setLoading(true);
    const result = loginApi(data, tenant);
    result?.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setLoadingMsg('fetching profile data...');
        const getProfileData = dispatch(getProfileDataApi(true));
        getProfileData?.then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            const data = dispatch(getAllServiceAddressesApi());
            data?.then((resVal) => {
              if (resVal?.status === 200 || resVal?.status === 201) {
                if (!isEmpty(res?.data?.past_due_invoices)) {
                  window.location.href = '/account';
                } else {
                  //   if (localStorage.getItem('lastUrl')) {
                  //   navigate(localStorage.getItem('lastUrl'));
                  //   localStorage.removeItem('lastUrl');
                  // } else {
                  navigate('/dashboard');
                }
              }
            });
          } else if (res?.status === 400 || res?.status === 401 || res?.status === 403) {
            setLoading(false);
            toast.error(res?.message || 'Something went wrong');
          } else {
            setLoading(false);
            toast.error(res?.message || 'Something went wrong');
          }
        });
      } else {
        setLoading(false);
        toast.error(res?.message || 'Invalid Credentials');
      }
    });
  };

  const signInWithGoogle = () => {
    const authCode = getGoogleAuthUrl(tenant, window.location.href);
    authCode?.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        window.location.href = res?.google_oauth_url;
      }
    });
  };
  useEffect(() => {
    const tempUrl = new URL(window.location.href);
    if (tempUrl?.searchParams?.size > 3) {
      setLoading(true);
      setLoadingMsg('verifying...');
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      debugger;
      if (code) {
        const tempCode = {
          auth_code: code,
        };
        const token = signinThroughGoogle(tenant, tempCode);
        token?.then((res) => {
          debugger;
          if (res?.status === 200 || res?.status === 201) {
            setLoadingMsg('fetching profile data...');
            const getProfileData = dispatch(getProfileDataApi(true));
            getProfileData?.then((res) => {
              if (res?.status === 200 || res?.status === 201) {
                const data = dispatch(getAllServiceAddressesApi());
                data?.then((resVal) => {
                  if (resVal?.status === 200 || resVal?.status === 201) {
                    if (!isEmpty(res?.data?.past_due_invoices)) {
                      window.location.href = '/account';
                    } else {
                      //   if (localStorage.getItem('lastUrl')) {
                      //   navigate(localStorage.getItem('lastUrl'));
                      //   localStorage.removeItem('lastUrl');
                      // } else {
                      navigate('/dashboard');
                    }
                  }
                });
              } else if (res?.status === 400 || res?.status === 401 || res?.status === 403) {
                setLoading(false);
                toast.error(res?.message || 'Something went wrong');
              } else {
                setLoading(false);
                toast.error(res?.message || 'Something went wrong');
              }
            });
          }
        });
      }
    }
  }, []);
  return (
    <div className='mx-auto w-[350px] px-4 py-8'>
      <h1 className='mb-6 text-center text-3xl font-bold text-slate-800'>Log in</h1>
      {loading ? (
        <Loader text={loadingMsg} classes='h-36 w-full rounded-lg mb-2 ' />
      ) : (
        <form className='mb-4 '>
          <div className='space-y-4'>
            <div>
              <label className='go-label-15' htmlFor='email'>
                Email
              </label>
              <input
                id='email'
                className={`go-inputtext-15 w-full ${errors?.email?.type === 'required' ? 'border-red-500' : ''}`}
                placeholder='Enter your email'
                type='email'
                name='email'
                required
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Please enter your email address',
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Invalid email address',
                  },
                })}
                autoComplete='off'
              />
              {errors.email?.message && <p className='go-textnormal-14 text-red-500'>{errors.email?.message}</p>}
            </div>

            <div>
              <label className=' go-label-15' htmlFor='password'>
                Password
              </label>

              <input
                id='password'
                className={`go-inputtext-15 w-full ${errors?.password?.type === 'required' ? 'border-red-500' : ''}`}
                placeholder='*********'
                type='password'
                name='password'
                required
                {...register('password', {
                  required: {
                    value: true,
                    message: 'Please enter your password',
                  },
                })}
                autoComplete='off'
              />
              {errors.password?.message && (
                <p className='go-textnormal-14 mb-4 text-red-500'>{errors.password?.message}</p>
              )}
            </div>
          </div>
          <div className='flex justify-end'>
            <div className='mr-1'>
              <Link className='text-xs font-semibold text-[#548ff7]' to='#' onClick={() => setType('forgotPassword')}>
                Forgot Password?
              </Link>
            </div>
          </div>
        </form>
      )}
      <div className='mb-2 border-slate-200 pb-2'>
        <div className='flex items-center justify-between'>
          <Link
            className='w-full rounded bg-[#548ff7] py-2 px-4 text-center text-xs font-semibold text-white'
            onClick={(e) => {
              handleSubmit(onSubmit)(e);
            }}
            to='#'
          >
            Login
          </Link>
        </div>
      </div>
      <div className='mb-2 border-slate-200 pb-2'>
        <div className='flex w-full items-center justify-between'>
          <button
            aria-label='Sign in with Google'
            className='border-button-border-light flex w-full items-center justify-center rounded-md border bg-white p-0.5 pr-3'
            onClick={() => {
              signInWithGoogle();
            }}
          >
            <div className='space-between flex h-9 w-9 items-center justify-center rounded-l bg-white'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className='h-5 w-5'>
                <title>Log In / Sign Up with Google</title>
                <desc>Google G Logo</desc>
                <path
                  d='M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z'
                  className='fill-google-logo-blue'
                ></path>
                <path
                  d='M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z'
                  className='fill-google-logo-green'
                ></path>
                <path
                  d='M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z'
                  className='fill-google-logo-yellow'
                ></path>
                <path
                  d='M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z'
                  className='fill-google-logo-red'
                ></path>
              </svg>
            </div>
            <span className='text-google-text-gray text-sm tracking-normal'>Log In / Sign Up with Google</span>
          </button>
        </div>
      </div>
    </div>
  );
}
export default SigninPage;
